import React from 'react';
import { css, styled } from 'styled-components';
import Link from './Link';

interface TagComponentProps {
  as?: string,
  children: React.ReactNode,
  language?: string,
  slug?: string,
}

interface StyledTagComponentProps {
  language?: string,
}

const StyledTagComponent = styled(Link) <StyledTagComponentProps>`
  align-items: center;
  background-color: ${props => props.language ? 'transparent' : 'var(--color-iron-300)'};
  border-radius: var(--border-radius);
  border: 1px solid ${props => props.language ? 'var(--color-iron-300)' : 'transparent'};
  color: var(--color-downriver-400);
  display: flex;
  font-family: var(--font-family-mono);
  font-size: var(--font-size-sm);
  font-weight: 300;
  min-height: 2.25rem;
  padding-inline: var(--space-xs);
  text-align: center;
  text-decoration: none;
  transition-duration: var(--transition-duration);
  transition-property: border;
  transition-timing-function: var(--transition-timing-function);
  user-select: none;

  &:hover {
    border: 1px solid var(--color-iron-400);
  }

  ${props => props.language && css`
    &::before {
      border-radius: 100%;
      content: '';
      display: inline-block;
      height: .75rem;
      margin-right: .25rem;
      position: relative;
      top: 2px;
      width: .75rem;

      ${props.language == 'typescript' && css`
        background-color: var(--color-typescript);
      `}
      
      ${props.language == 'rust' && css`
        background-color: var(--color-rust);
      `}

      ${props.language == 'python' && css`
        background-color: var(--color-python);
      `}

      ${props.language == 'go' && css`
        background-color: var(--color-go);
      `}

      ${props.language == 'javascript' && css`
        background-color: var(--color-javascript);
      `}

      ${props.language == 'java' && css`
      background-color: var(--color-java);
      `}
  
      ${props.language == 'kotlin' && css`
        background-color: var(--color-kotlin);
      `}

      ${props.language == 'c++' && css`
      background-color: var(--color-cpp);
      `}

      ${props.language == 'c' && css`
      background-color: var(--color-c);
      `}
    }
  `}
`;

export default function TagComponent({ children, slug, as, language }: TagComponentProps) {
  return (
    <>
      {as && (
        <StyledTagComponent language={language} as={as}>
          {children}
        </StyledTagComponent>
      )}
      {!as && slug && (
        <StyledTagComponent language={language} as={as} routeName="blog.tag" routeParams={{ slug: slug.toLowerCase() }}>
          {children}
        </StyledTagComponent>
      )}
    </>
  );
}
