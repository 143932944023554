import { css } from 'styled-components';
import { breakpoints } from '../styled/utils';

import IbmPlexMono300Woff from '../../assets/fonts/ibm-plex-mono-v7-latin-300.woff';
import IbmPlexMono300Woff2 from '../../assets/fonts/ibm-plex-mono-v7-latin-300.woff2';
import IbmPlexMono500Woff from '../../assets/fonts/ibm-plex-mono-v7-latin-500.woff';
import IbmPlexMono500Woff2 from '../../assets/fonts/ibm-plex-mono-v7-latin-500.woff2';

import IbmPlexSans300ItalicWoff from '../../assets/fonts/ibm-plex-sans-v9-latin-300italic.woff';
import IbmPlexSans300ItalicWoff2 from '../../assets/fonts/ibm-plex-sans-v9-latin-300italic.woff2';
import IbmPlexSans300Woff from '../../assets/fonts/ibm-plex-sans-v9-latin-300.woff';
import IbmPlexSans300Woff2 from '../../assets/fonts/ibm-plex-sans-v9-latin-300.woff2';
import IbmPlexSans500Woff from '../../assets/fonts/ibm-plex-sans-v9-latin-500.woff';
import IbmPlexSans500Woff2 from '../../assets/fonts/ibm-plex-sans-v9-latin-500.woff2';

export const root = css`
  :root {
    --color-downriver-300: #3b4b5b;
    --color-downriver-400: #0a2540;
    --color-golden-tainoi-300: #ffe3af;
    --color-golden-tainoi-400: #ffc65f;
    --color-iron-200: #fafafb;
    --color-iron-300: #f5f6f7;
    --color-iron-400: #dce0e3;
    --color-pastel-green-200: #2b8543;
    --color-pastel-green-300: #7ee99a;
    --color-pastel-green-400: #48e070;
    --color-royal-blue-300: #afb6f0;
    --color-royal-blue-400: #5e6ce0;
    --color-sunset-orange-300: #ffa2a2;
    --color-sunset-orange-400: #ff4a4a;
    --color-transparent: transparent;
    --color-white: #ffffff;
    --color-github: #f1502f;
    --color-linkedin: #2867b2;
    --color-twitter: #1da1f2;
    --color-hljs-title: #900;
    --color-hljs-built-in: #0086b3;
    --color-hljs-symbol: #990073;
    --color-hljs-number: #008080;
    --color-hljs-string: #df5000;
    --color-hljs-addition: #55a532;
    --color-hljs-addition-background: #eaffea;
    --color-hljs-deletion: #bd2c00;
    --color-hljs-deletion-background: #ffecec;
    --color-rust: #dea584;
    --color-php: #4f5d95;
    --color-python: #3673a5;
    --color-javascript: #f1e05a;
    --color-typescript: #2b7489;
    --color-go: #00add8;
    --color-cpp: #f34b7d;
    --color-c: #555555;
    --color-java: #b07219;
    --color-kotlin: #A97BFF;
    --transition-duration: 250ms;
    --transition-timing-fuction: ease-in-out;
    --container-padding: 0.75rem;
    --container-width: 90rem;
    --font-family-mono: 'IBM Plex Mono', monospace;
    --font-family: 'IBM Plex Sans', sans-serif;
    --font-size-base: 1rem;
    --font-size-scale: 1.25;
    --font-size-sm: 0.75rem;
    --font-size-md: var(--font-size-base);
    --font-size-lg: calc(var(--font-size-md) * var(--font-size-scale));
    --font-size-xl: calc(var(--font-size-lg) * var(--font-size-scale));
    --font-size-xxl: calc(var(--font-size-xl) * var(--font-size-scale));
    --line-height-tight: 1.125;
    --line-height: 1.6;
    --baseline: calc(var(--font-size-md) * var(--line-height));
    --baseline-sm: calc(var(--baseline) / 2);
    --baseline-md: var(--baseline);
    --baseline-lg: calc(var(--baseline) * 1.5);
    --gutter: 0.75rem;
    --border-radius-sm: 0.25rem;
    --border-radius: 0.5rem;
    --space-xxs: 0.125;
    --space-xs: 0.25rem;
    --space-sm: 0.375rem;
    --space-md: 0.75rem;
    --space-lg: 1.5rem;
    --space-xl: 2.25rem;
    --space-xxl: 3rem;
    --space-3xl: 4.5rem;
    --space-baseline: var(--baseline);
    --header-height: 3rem;
    --header-height-scrolled: 3rem;
    --box-shadow: rgba(124, 124, 124, 0.33) 0px 8px 28px 0px;

    @media ${breakpoints.md} {
      --font-size-scale: 1.5;
      --container-padding: 1.5rem;
      --gutter: 1.5rem;
      --space-xxs: 0.25rem;
      --space-xs: 0.5rem;
      --space-sm: 0.75rem;
      --space-md: 1.5rem;
      --space-lg: 3rem;
      --space-xl: 4.5rem;
      --space-xxl: 6rem;
      --header-height: 6rem;
      --header-height-scrolled: 4.5rem;
    }

    @media ${breakpoints.lg} {
      --container-padding: 3rem;
    }

    @media ${breakpoints.xl} {
      --container-padding: 6rem;
    }
  }
`;

export const fontFace = css`
  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''), url('${IbmPlexSans300Woff2}') format('woff2'),
      url('${IbmPlexSans300Woff}') format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 300;
    font-display: swap;
    src: local(''), url('${IbmPlexSans300ItalicWoff2}') format('woff2'),
      url('${IbmPlexSans300ItalicWoff}') format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''), url('${IbmPlexSans500Woff2}') format('woff2'),
      url('${IbmPlexSans500Woff}') format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''), url('${IbmPlexMono300Woff2}') format('woff2'),
      url('${IbmPlexMono300Woff}') format('woff');
  }

  @font-face {
    font-family: 'IBM Plex Mono';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''), url('${IbmPlexMono500Woff2}') format('woff2'),
      url('${IbmPlexMono500Woff}') format('woff');
  }
`;

export const body = css`
  body {
    color: var(--color-downriver-400);
    font-family: var(--font-family);
    font-size: var(--font-size-md);
    font-weight: 300;
    line-height: var(--line-height);
  }

  * {
    box-sizing: border-box;
  }
`;

export const typography = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }

  h1 {
    font-size: var(--font-size-xxl);
    line-height: var(--line-height-tight);
  }

  h2 {
    font-size: var(--font-size-xl);
    line-height: var(--line-height-tight);
  }

  h3 {
    font-size: var(--font-size-lg);
    line-height: var(--line-height-tight);
  }

  strong,
  b {
    font-weight: 500;
  }

  code {
    font-family: var(--font-family-mono);
  }

  code {
    font-size: var(--font-size-sm);
    word-break: keep-all;
    word-wrap: normal;
  }

  a,
  abbr {
    color: inherit;
    text-decoration: none;
  }

  em,
  i {
    font-style: italic;
  }

  .underline {
    text-decoration: underline;
  }

  .medium {
    font-weight: 600;
  }
`;

export const highlight = css`
  .hljs-keyword,
  .hljs-title,
  .hljs-meta {
    font-weight: 500;
  }

  .hljs-comment,
  .hljs-meta {
    color: var(--color-downriver-300);
  }

  .hljs-title {
    color: var(--color-hljs-title);
  }

  .hljs-symbol {
    color: var(--color-hljs-symbol);
  }

  .hljs-attribute,
  .hljs-built_in {
    color: var(--color-hljs-built-in);
  }

  .hljs-number {
    color: var(--color-hljs-number);
  }

  .hljs-string {
    color: var(--color-hljs-string);
  }

  .hljs-addition {
    color: var(--color-hljs-addition);
    background-color: var(--color-hljs-addition-background);
  }

  .hljs-deletion {
    color: var(--color-hljs-deletion);
    background-color: var(--color-hljs-deletion-background);
  }
`;

export const styles = css`
  img {
    height: auto;
    max-width: 100%;

    &.full {
      height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }

    &.fluid {
      width: 100%;
    }
  }

  html {
    scroll-behavior: smooth;
  }

  // prevent SVG flickering on SSR load
  .fa-w-12 {
    width: 0.75em;
  }

  .fa-w-14 {
    width: 0.875em;
  }

  .fa-w-16 {
    width: 1em;
  }

  svg:not(:root).svg-inline--fa,
  svg:not(:host).svg-inline--fa {
    overflow: visible;
    box-sizing: content-box;
  }

  .svg-inline--fa {
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
  }

  .gray-out {
    opacity: 0.3;
    pointer-events: none;
  }

  [id] {
    scroll-margin-block-start: calc(
      var(--header-height-scrolled) + var(--gutter)
    );
  }
`;
