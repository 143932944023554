import { RouterState } from './routerMiddleware';

function loadComponent(name: string) {
  if (name == 'blog' || name == 'blog.tag') {
    return import('./components/pages/blog/Blog');
  }

  if (name == 'blog.view') {
    return import('./components/pages/blog/view/BlogView');
  }

  if (name == 'interop') {
    return import('./components/pages/interop/BlogInterop')
  }

  if (name == 'expertises.rust-engineering') {
    return import('./components/pages/expertises/RustEngineering');
  }

  if (name == 'expertises.rust.training') {
    return import('./components/pages/services/Training');
  }

  if (name == 'expertises.rust.explore-rust') {
    return import('./components/pages/services/ExploreRust');
  }

  if (name == 'embedded.rust') {
    return import('./components/pages/embedded/rust/EmbeddedRustView');
  }

  if (name == 'our-work') {
    return import('./components/pages/cases/CasesView');
  }

  if (name == 'our-work.testimonials') {
    return import('./components/pages/cases/TestimonialsView');
  }

  if (name == 'about') {
    return import('./components/pages/about/AboutView');
  }

  if (name == 'about.author'){
    return import('./components/pages/our-people/DetailedAboutEmployee');
  }

  if (name == 'about.author.blogs') {
    return import('./components/pages/our-people/AboutEmployeeBlogs');
  }

  if (name == 'our-people') {
    return import('./components/pages/our-people/OurPeopleView');
  }

  if (name == 'contact') {
    return import('./components/pages/contact/ContactView');
  }

  if (name == 'opensource') {
    return import('./components/pages/open-source/OpenSourceView');
  }

  if (name == 'pendulum') {
    return import('./components/pages/pendulum/Pendulum');
  }

  if (name == 'vacancies') {
    return import('./components/pages/vacancies/Vacancies');
  }

  if (name == 'vacancies.view') {
    return import('./components/pages/vacancies/view/VacanciesView');
  }

  if (name == 'privacy') {
    return import('./components/pages/privacy/PrivacyView');
  }
  
  if (name == 'systems-engineering') {
    return import('./components/pages/systems-engineering/SystemsEngineering');
  }

  if (name == 'web-engineering') {
    return import('./components/pages/web-engineering/WebEngineering.tsx');
  }

  if (name == 'embedded-world') {
    return import('./components/pages/embedded-world/EmbeddedWorldView.tsx');    
  }

  return Promise.resolve();
}

export default function lazyLoadMiddleware() {
  return async (toState: RouterState) => {
    let component = null;

    if (toState?.name) {
      const module = await loadComponent(toState.name);

      if (module) {
        component = module.default;
      }
    }
  
    return { ...toState, component };
  };
}
