import { useRoute } from 'react-router5';
import { RouterState } from '../routerMiddleware';
import { useMemo } from 'react';

export interface Suggestion {
  slug: string;
  type: 'tag' | 'author',
}

export function normalize(input: string) {
  return input.toLocaleLowerCase()
    .replace(/\u00fc/g, 'u')
    .replace(/\u00e4/g, 'a')
    .replace(/\u00f6/g, 'o');
}

export default function useBlogSearchSuggestions(search: string): Array<Suggestion> {
  const { route } = useRoute();
  const state = route as RouterState;

  const tags = state?.data?.tags || [];
  const authors = state?.data?.users || [];

  // collect all tags and authors as suggestions
  const allSuggestion = useMemo(() => {
    const all = [
      ...authors.map(author => ({ slug: normalize(author.name), type: 'author' } as Suggestion)),
      ...tags.map(tag => ({ slug: normalize(tag.name), type: 'tag' } as Suggestion)),
    ];

    const allSlugs = all.map(suggestion => suggestion.slug);

    return all
      .filter((item, i) => allSlugs.indexOf(item.slug) === i)
      .filter((item) => item.slug.length > 0);
  }, [tags, authors]);

  const query = normalize(search);

  if (query.length === 0) {
    return [];
  }

  // filter on current search term
  return allSuggestion
    .filter((suggestion) => (
      suggestion.slug.includes(query) ||
      query.includes(suggestion.slug)
    ))
    .sort((a, b) => a.slug.indexOf(query) - b.slug.indexOf(query))
    .slice(0, 10);
}
