import { breakpoints } from '../styled/utils';
import { Client } from '../types';
import Background from './layout/Background';
import Col from './layout/Col';
import Container from './layout/Container';
import Grid from './layout/Grid';
import Heading from './Heading';
import React from 'react';
import Sections from './layout/Sections';
import { styled } from 'styled-components';
import Flex from './layout/Flex';
import Text from './Text';
import Paragraph from './Paragraph';

interface ClientsProps {
  clients: Client[],
  title?: React.ReactNode,
  bottomTitle?: string,
}

const ClientsSection = styled.div`
  background-color: var(--color-iron-300);
  overflow: hidden;
  padding-bottom: var(--space-xxl);
  padding-top: var(--space-xxl);
  position: relative;
  z-index: 0;
`;

const ClientsCol = styled(Col)`
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: var(--baseline);
`;

const ClientsLogo = styled.img`
  filter: grayscale(100%);
  mix-blend-mode: multiply;
  opacity: 0.75;
  padding-left: var(--space-lg);
  padding-right: var(--space-lg);
  transition-duration: var(--transition-duration);
  transition-property: filter, opacity;
  transition-timing-function: var(--transition-timing-function);
  object-fit: contain;

  @media ${breakpoints.md} {
    padding-left: var(--space-md);
    padding-right: 0;
  }

  @media ${breakpoints.lg} {
    padding-left: var(--space-lg);
    padding-right: var(--space-lg);
  }

  &:hover {
    filter: grayscale(0);
    opacity: 1;
  }
`;

export default function Clients({ title, clients, bottomTitle }: ClientsProps) {
  return (
    <Sections
      $rowGap="baseline"
    >
      <Container>
        <Heading as="h3">
          {title ?
            <>{title}</>
            :
            <Text en="Clients & partners" />
          }
        </Heading>
      </Container>
      <ClientsSection>
        <Background $withContainer $cols={{ sm: 2, md: 4 }} $lineColor="iron-400" $zIndex={0} />
        <Container>
          <Sections $rowGap="space-xxl">
            <Grid $rowGap="space-xxl" $cols={{ sm: 2, md: 4 }} $columnGap="gutter">
              {clients.map((client, index) => (
                <ClientsCol as="a" href={client.link} target="_blank" key={index}>
                  <ClientsLogo suppressHydrationWarning src={client.image} alt={client.name} title={client.name} width="444" height="152" />
                  {client.description && (
                    <strong><Text en={client.description} /></strong>
                  )}
                </ClientsCol>
              ))}
            </Grid>
            {bottomTitle &&
              <Flex $justify={{ sm: 'center' }}>
                <Paragraph $size="m" as="strong">{bottomTitle}</Paragraph>
              </Flex>
            }
          </Sections>
        </Container>
      </ClientsSection>
    </Sections>
  );
}
