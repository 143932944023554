import { State, StateMeta, constants } from 'router5';
import { getArticle, getArticlePreview, getIndexData, getUsers, getVacancies } from './api';
import {Article, Case, CaseCategory, OsProject, Tag, User, Vacancy} from './types';
import { isBrowser } from './util';

export const defaultLanguage = 'en';
export const languages = ['en']

export type UsersById = {[id: string]: User};
export type TagsById = {[id: string]: Tag};
export type CaseCategoryById = {[id: string]: CaseCategory};

export interface Data {
  users: Array<User>;
  usersById: UsersById;
  caseCategoriesById: CaseCategoryById;
  userDetailsLoaded: boolean,
  articles: Array<Article>;
  osprojects: Array<OsProject>;
  osprojectDetailsLoaded: boolean;
  cases: Array<Case>;
  vacancyCount: number;
  vacancies: null | Array<Vacancy>;
  tags: Array<Tag>;
  tagsById: TagsById;
}

export interface RouterState extends State {
  data: Data;
  route: State;
  component: React.ElementType,
  error?: number,
  meta: StateMeta & {
    source: string,
  }
}

let lastScrollPosition = 0;
function setScroll(fromState: RouterState, toState: RouterState)  {
  // store scroll position when navigation with browser history
  if (toState.meta.source !== 'popstate') {
    lastScrollPosition = window.scrollY;
  }
  // do not scoll on search in the blog
  if (fromState?.name === 'blog' && toState?.name === 'blog') {
    return;
  }

  if (toState.params.limit) {
    window.scrollY = 0;
  } else if (toState.meta.source === 'popstate') {
    window.requestAnimationFrame(() => window.scrollTo(0, lastScrollPosition));
  } else if (!fromState || !fromState.route || toState.route.path !== fromState.route.path) {
    window.requestAnimationFrame(() => window.scrollTo(0, 0));
  }
}

export default function routerMiddleware() {
  return async (toState: RouterState, fromState: RouterState) => {
    // parse id's as number
    if (toState.params.id) {
      toState.params.id = parseInt(toState.params.id, 10);
    }

    if (toState.name === constants.UNKNOWN_ROUTE && toState.path === '/') {
      return Promise.reject({ redirect: { name: 'home', params: { lang: defaultLanguage } }});
    }

    // fetch data from api
    try {
      const data: Data = fromState?.data || await getIndexData();

      if ((
        toState.name.startsWith('our-people') ||
        toState.name.startsWith('about') ||
        toState.name.startsWith('interop') ||
        toState.name.startsWith('expertises.rust.training')
      ) && !data.userDetailsLoaded) {
        data.users = await getUsers();
        data.usersById = data.users.reduce((obj, user) => ({ ...obj, [user.id]: user }), {});
        data.userDetailsLoaded = true;
      }

      if (toState.name === 'blog.view') {
        let found = false;
        for (let i = 0; i < data.articles.length; i += 1) {
          if (data.articles[i].id === toState.params.id) {
            if (!data.articles[i].content) {
              data.articles[i] = await getArticle(data.articles[i]);
            }
            found = true;
            break;
          }
        }

        if (!found) {
          if (toState.params.preview === 'yes') {
            try {
              const article = await getArticlePreview(toState.params.id, data);
              data.articles.unshift(article);
            } catch(e) {
              console.warn(e);
              return { ...toState, data, error: 404 };
            }
          } else {
            return { ...toState, data, error: 404 };
          }
        }
      }

      if (toState.name.startsWith('vacancies') && !data.vacancies) {
        data.vacancies = await getVacancies();

        if (!Array.isArray(data.vacancies) || data.vacancies.length === 0) {
          return { ...toState, data, error: 404 };
        }
      }

      // scroll to top on navigate
      if (isBrowser) {
        setScroll(fromState, toState);
      }
  
      return { ...toState, data };
    } catch (e) {
      console.warn(e);
      return { ...toState, error: 404 };
    }
  };
}
