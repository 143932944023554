import { createRouter, Router } from 'router5';
import lazyLoadMiddleware from './lazyLoadMiddleware';
import routerMiddleware, { defaultLanguage } from './routerMiddleware';

export const routes = [
  { name: 'home', path: '' },
  { name: 'contact', path: '/contact' },
  { name: 'privacy', path: '/privacy' },
  { name: 'case-study-soil-heroes', path: '/case-study-soil-heroes' },
  { name: 'embedded-world', path: '/embedded-world' },
  {
    name: 'embedded',
    path: '/embedded',
    children: [
      {
        name: 'rust',
        path: '/rust'
      },
    ],
  },
  {
    name: 'about',
    path: '/about',
    children: [
      {
        name: 'author',
        path: '/:id<\\d+>/:name',
        children: [{
          name: 'blogs',
          path: '/blogs',
        }]
      }
    ]
  },
  {
    name: 'our-people',
    path: '/our-people',
  },
  {
    name: 'opensource',
    path: '/open-source',
  },
  {
    name: 'pendulum',
    path: '/pendulum',
  },
  {
    name: 'blog',
    path: '/blog',
    children: [
      {
        name: 'view',
        path: '/:id<\\d+>/:slug',
      },
      {
        name: 'tag',
        path: '/:slug',
      },
    ],
  },
  {
    name: 'interop',
    path: '/interop'
  },
  {
    name: 'vacancies',
    path: '/vacancies',
    children: [
      {
        name: 'view',
        path: '/:id<\\d+>/:slug',
      },
    ],
  },
  {
    name: 'coming-soon',
    path: '/coming-soon',
    children: [
      {
        name: 'service',
        path: '/:slug',
      },
    ],
  },
  {
    name: 'expertises',
    path: '/expertise',
    children: [
      {
        name: 'rust',
        path: '/rust',
        children: [
          {
            name: 'training',
            path: '/training',
          },
          {
            name: 'explore-rust',
            path: '/explore-rust',
          },
          {
            name: 'teamaugmentation',
            path: '/team-augmentation',
          }
        ]
      },
      {
        name: 'rust-engineering',
        path: '/rust-engineering',
      },
      {
        name: 'view',
        path: '/:slug',
      },
    ],
  },
  {
    name: 'our-work',
    path: '/our-work',
    children: [
      {
        name: 'testimonials',
        path: '/testimonials',
      },
    ],
  },
  {
    name: 'systems-engineering',
    path: '/systems-engineering'
  },
  {
    name: 'web-engineering',
    path: '/web-engineering'
  }
].map((route) => ({
  ...route,
  path: `/:lang${route.path}`
}));

export const createAppRouter = (): Router => {
  const router: Router = createRouter(routes, {
    allowNotFound: true,
    queryParamsMode: 'loose',
    defaultParams: { lang: defaultLanguage }
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router.useMiddleware(routerMiddleware as any);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  router.useMiddleware(lazyLoadMiddleware as any);

  return router;
};
