import { useRoute } from 'react-router5';
import { RouterState } from '../routerMiddleware';
import { Article } from '../types';
import { normalize } from './useBlogSearchSuggestions';

export default function useArticles(allowedArticles: number[] = []): Array<Article> {
  const { route } = useRoute();
  const state = route as RouterState;

  let articles = state?.data?.articles;

  if (state?.name === 'blog.tag') {
    const slug = state?.params?.slug;
    articles = articles.filter(article => article.tags.map(tag => tag.name.toLocaleLowerCase()).includes(slug));
  }

  if (state?.params?.search) {
    const search = state?.params?.search.toLocaleLowerCase();
    articles = articles.filter(article => (
      article.tags.map(tag => normalize(tag.name)).includes(search)
      || normalize(article.title).includes(search)
      || normalize(article.summary).includes(search)
      || article.authors.map(user => normalize(user.name)).includes(search)
    ));
  }

  if (state?.name === 'about.author' || state?.name === 'about.author.blogs') {
    const authorId = state?.params?.id;
    articles = articles.filter(article => article.authors.map(user => user.id).includes(authorId));
  }

  return (articles || []).filter((article: Article) => allowedArticles.length == 0 || allowedArticles.includes(article.id));
}
